import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  crmConfig = this.localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;
  paymentServerUrl = environment.paymentServerUrl;

  _searchResults$ = new BehaviorSubject<any>(undefined);
  searchedTerm: string = '';
  isContact!: boolean;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  // getSearchResult(url: string, searchTerm: string, itemsPerPage?: number, currentPage?: number): Observable<any> {
  //   this.searchedTerm = searchTerm
  //   var finalURL = ''

  //   if(url.includes('inventory')) {
  //     url = '/inventories'
  //   }

  //   if(url.includes('setting')) {
  //     url = '/customers/setting'
  //   }

  //   if(url.includes('coupon-management')) {
  //     url = '/couponCode'
  //   }

  //   if(url.includes('support') || url.includes('contactus')) {
  //     if(url.includes('support')) {
  //       url = '/support-request'
  //       finalURL = `${this.serverUrl}${url}?type=support&q=${searchTerm}`
  //     }

  //     if(url.includes('contactus')) {
  //       url = '/support-request'
  //       finalURL = `${this.serverUrl}${url}?type=contact&q=${searchTerm}`
  //     }
  //   }

  //   else {
  //     finalURL = `${this.serverUrl}${url}/search?q=${searchTerm}`
  //   }

  // getSearchResult(url: string, searchTerm: string, itemsPerPage?: number, currentPage?: number): Observable<any> {
  //   this.searchedTerm = searchTerm
  //   var finalURL = ''

  //   if(searchTerm && searchTerm.length > 3) {
  //     if(itemsPerPage && currentPage) {
  //       return this.http.get(`${finalURL}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`);
  //     }
  //     else {
  //       return this.http.get(`${finalURL}`);
  //     }
  //   }

  //   else {
  //     return this.http.get(`${this.serverUrl}${url}`);
  //   }
  // }

  getSearchResult(
    url: string,
    searchTerm: string,
    itemsPerPage?: number,
    currentPage?: number
  ): Observable<any> {
    let finalURL = '';
    const type = '';
    this.searchedTerm = searchTerm;
    let customerId;

    if (url.includes('inventory')) {
      finalURL = `${this.serverUrl}/inventories`;
    } else if (url.includes('coupon-management')) {
      finalURL = `${this.paymentServerUrl}/couponCode`;
    } else if (
      url.includes('support') ||
      url.includes('contactus') ||
      url.includes('contact') ||
      url.includes('affiliate')
    ) {
      let type: string;
      customerId = this.localStorage.getSelectedCustomer();
      if (url.includes('support')) {
        type = 'support';
      } else if (url.includes('contact')) {
        type = 'contact';
      } else {
        type = 'affiliate';
      }
      finalURL = `${this.serverUrl}/support-request?type=${type}`;
    } else if (url?.includes('agents')) {
      customerId = this.localStorage.getSelectedCustomer();
      finalURL = `${this.serverUrl}/agents/search?customerId=${customerId}`;
    } else if (url?.includes('user-management')) {
      finalURL = `${this.serverUrl}/users`;
    } else {
      finalURL = `${this.serverUrl}${url}`;
    }

    if (searchTerm && searchTerm.length > 0) {
      if (!customerId) {
        type
          ? (finalURL += `&q=${searchTerm}`)
          : (finalURL += `/search?${
              url.includes('coupon-management') ? 'couponCode' : 'q'
            }=${searchTerm}`);
      } else {
        finalURL += `&q=${searchTerm}`;
      }
      if (itemsPerPage && currentPage) {
        finalURL += `&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`;
      }
    }

    if (
      url === '/' ||
      url.includes('setting') ||
      url.includes('reports') ||
      url.includes('theme-editor') ||
      (url.includes('products') && !url.includes('products/agents'))
    ) {
      return of(null);
    }
    return this.http.get(finalURL);
  }

  getResults() {
    return this._searchResults$.asObservable();
  }

  setResults(results: any) {
    this._searchResults$.next(results);
  }
}
