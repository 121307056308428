import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AlertService, SubscriptionsService } from '../../service';
import { DialogComponent } from '../../service/dialog';

@Component({
  selector: 'app-subscription-info',
  templateUrl: './subscription-info.component.html',
  styleUrls: ['./subscription-info.component.scss'],
})
export class SubscriptionInfoComponent implements OnInit {
  dialogRef: DialogComponent;
  subscriptionDetails: any;
  totalData!: number;
  usedData!: number;
  copyText: string = 'Copy';
  currencyType: string = 'USD';
  dataUnit!: string;
  inProgress: boolean = false;
  usedDataForGuage!: number;
  dataUsageAllowanceType = 'LIMITED';

  constructor(
    private viewContainer: ViewContainerRef,
    private subscriptionService: SubscriptionsService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.currencyType = localStorage.getItem('currency')!;
    this.subscriptionDetails = this.dialogRef.context?.data;
    this.getSubscriptionDataUsage(this.subscriptionDetails?._id);
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }

  /**
   * @description Value usage goes from MB to GB.
   *
   * @returns
   */
  getUsedDataCa() {
    return parseFloat((this.totalData * 0.68).toFixed(2));
  }

  /**
   * @description Get subscription data usage
   *
   * @param id
   */
  getSubscriptionDataUsage(id: string) {
    this.inProgress = true;
    this.subscriptionService.getSubscriptionDataUsage(id).subscribe({
      next: (res: any) => {
        if (res) {
          if (res.dataUsageAllowanceType === 'UNLIMITED') this.dataUsageAllowanceType = 'UNLIMITED';
          if (res.used_data_size_in_MB >= 1024 && res.used_data_size_in_GB > 0) {
            this.usedData = res.used_data_size_in_GB;
            this.totalData = res.total_data_size_in_GB;
          } else {
            this.usedData = res.used_data_size_in_MB;
            this.totalData = res.total_data_size_in_MB;
          }
          this.usedDataForGuage = this.usedData;

          res?.used_data_size_in_MB >= 1024 && res?.used_data_size_in_GB > 0
            ? (this.dataUnit = 'GB')
            : (this.dataUnit = 'MB');

          this.inProgress = false;
        }
      },
      error: (err: any) => {
        this.usedDataForGuage = 0;
        this.dataUnit = 'MB';
        this.inProgress = false;
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description Copy user email
   *
   * @param event
   * @param email
   * @returns
   */
  copyToClipboard(event: MouseEvent, email: string | undefined) {
    event.preventDefault();

    if (!email) {
      return;
    }
    navigator.clipboard.writeText(email);
  }
}
