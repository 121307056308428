import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';
import { Role, RoleConfig } from 'src/assets/role/role.config';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  role!: Role;

  constructor(private localStorage: LocalStorageService) {
    this.role = this.localStorage.getRole() as Role;
  }

  hasPermission<
    Section extends keyof RoleConfig[Role],
    Permission extends keyof RoleConfig[Role][Section],
  >(section: Section, permission: Permission): boolean {
    // Type assertion ensures TypeScript understands the returned value is a boolean
    return RoleConfig[this.role]?.[section]?.[permission] as boolean;
  }
}
