import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../service';
import { AgentService } from '../../service/agent.service';
import { DialogComponent } from '../../service/dialog';
import { trimSpaceValidator } from '../../validators/trimSpaceValidator';

@Component({
  selector: 'app-invite-agent',
  templateUrl: './invite-agent.component.html',
  styleUrls: ['./invite-agent.component.scss'],
})
export class InviteAgentComponent implements OnInit {
  dialogRef: DialogComponent;
  inviteAgentForm!: FormGroup;
  title: string = 'Invite Agent';
  submitted = false;
  customerId!: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private agentService: AgentService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.customerId = this.dialogRef.context?.customerId;
    this.initForm();

    // On mobile number change
    this.inviteAgentForm.controls['mobile']?.valueChanges.subscribe((phoneNumber: any) => {
      const tempNo = phoneNumber?.split(' ') || [];
      if (tempNo.length === 2) {
        const countryCodeLength = tempNo[0].length + 1;
        this.inviteAgentForm.controls['mobile']?.setValidators([
          Validators.required,
          Validators.minLength(countryCodeLength + 7),
          Validators.maxLength(countryCodeLength + 15),
        ]);
        this.inviteAgentForm.controls['mobile']?.updateValueAndValidity();
      }
    });
  }

  /**
   * @description Initiate invite agent form
   */
  private initForm() {
    this.inviteAgentForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern('^[a-zA-Z ]*$'),
        trimSpaceValidator,
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.minLength(7),
        Validators.maxLength(254),
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,20}$'),
      ]),
      mobile: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
      ]),
    });
  }

  /**
   * @description Create agent
   *
   * @returns
   */
  submit() {
    this.submitted = true;

    if (this.inviteAgentForm.invalid) {
      return;
    }

    const agent = this.inviteAgentForm.value;
    const phoneNumberData = agent.mobile.split(' ');

    const finalAgentObj = {
      customerId: this.customerId,
      name: agent.name,
      email: agent.email,
      countryCode: phoneNumberData[0],
      mobile: phoneNumberData[1],
    };

    this.agentService.inviteAgent(finalAgentObj).subscribe({
      next: (result) => {
        this.submitted = false;
        this.dialogRef.close.emit({ ...result, name: finalAgentObj.name });
      },
      error: (err) => {
        this.submitted = false;
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }
}
