import { ApplicationRef, EnvironmentInjector, Injectable, createComponent } from '@angular/core';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private openDialogs: Array<{ element: HTMLElement; ref: any; contextBody: string }> = [];

  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef
  ) {}

  openModal(templateRef: any, userConfig: any) {
    // Generate a unique ID based on `templateRef` and timestamp or some unique property
    const uniqueId = `${templateRef}_${Date.now()}`;

    const contextBody = userConfig.context?.body?.toLowerCase() || uniqueId;

    // Check if a modal with the same context body is already open
    const existingDialog = this.openDialogs.find((dialog) => dialog.contextBody === contextBody);
    if (existingDialog) {
      return existingDialog.ref; // Return the existing dialog reference
    }

    // Create element
    const popup = document.createElement('popup-component');

    let className = 'main-container';
    if (
      document.getElementsByClassName('body-container') &&
      document.getElementsByClassName('body-container')[0]
    ) {
      className = 'body-container';
    }
    document.getElementsByClassName(className)[0].appendChild(popup);

    // Create the component and wire it up with the element
    const dialogComponentRef = createComponent(DialogComponent, {
      environmentInjector: this.injector,
      hostElement: popup,
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(dialogComponentRef.hostView);

    // Track the dialog with its context body
    this.openDialogs.push({ element: popup, ref: dialogComponentRef, contextBody });

    // Listen to the close event
    dialogComponentRef.instance.close.subscribe(() => {
      this.closeModal(popup, dialogComponentRef);
    });

    // Set the message
    dialogComponentRef.instance.template = templateRef;
    dialogComponentRef.instance.context = userConfig.context;
    dialogComponentRef.instance.cssClass = userConfig.cssClass;

    // Close all modals if condition matches
    if (userConfig.context?.body?.toLowerCase() === 'user not found') {
      this.closeAllModals();
    }

    return dialogComponentRef;
  }

  private closeModal(popup: HTMLElement, dialogComponentRef: any) {
    document.body.removeChild(popup);
    this.applicationRef.detachView(dialogComponentRef.hostView);
    this.openDialogs = this.openDialogs.filter((dialog) => dialog.element !== popup);
  }

  closeAllModals() {
    this.openDialogs.forEach(({ element, ref }) => {
      this.applicationRef.detachView(ref.hostView);
      document.body.removeChild(element);
    });
    this.openDialogs = [];
  }
}
