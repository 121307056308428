import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService, CustomerService, DashboardService, UsersService } from '../../service';
import { ConfigurationService } from '../../service/configuration.service';
import { EncryptDescryptService } from '../../service/encrypt-descrypt.service';
import { LocalStorageService } from '../../service/local-storage.service';
import { SidebarService } from '../../service/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isDarkTheme = false;
  defaultId: any;
  activeUrl = 'dashboard';
  show: boolean = false;
  customerList: any = [];
  parentCustomer: any;
  sidebarMenuList: Array<any> = [];
  userDetails: any = {};
  isParentActive: any;
  clientConfig!: any;
  routeConfig: any;
  isCustomerSelected = false;
  isRole: any;
  adminMenuList: any = [];
  superAdminMenuList: any = [];
  selectedCustomerList: any = [];
  selectedCustomerName: any;
  adminCustomerName: any;
  getCustomerSubscription: Subscription = Subscription.EMPTY;
  logoSrc: any;
  originalBrandColors: any = [
    {
      name: 'Primary',
      darkValue: '#6365EF',
      lightValue: '#6365EF',
      rootName: '--preview-color-primary',
      darkKey: 'darkPrimary',
      lightKey: 'primary',
    },
    {
      name: 'Secondary',
      darkValue: '#1E1E1E',
      lightValue: '#F0F0FE',
      rootName: '--preview-color-secondary',
      darkKey: 'darkSecondary',
      lightKey: 'secondary',
    },
    {
      name: 'Primary Font',
      darkValue: '#FFFFFF',
      lightValue: '#000000',
      rootName: '--preview-color-primary-font',
      darkKey: 'darkPrimaryFont',
      lightKey: 'primaryFont',
    },
    {
      name: 'Secondary Font',
      darkValue: '#949494',
      lightValue: '#949494',
      rootName: '--preview-color-secondary-font',
      darkKey: 'darkSecondaryFont',
      lightKey: 'secondaryFont',
    },
    {
      name: 'Button',
      darkValue: '#6365EF',
      lightValue: '#6365EF',
      rootName: '--preview-color-button',
      darkKey: 'darkButton',
      lightKey: 'button',
    },
    {
      name: 'Secondary Button',
      darkValue: '#1E1E1E',
      lightValue: '#D0D5DD',
      rootName: '--preview-color-secondary-button',
      darkKey: 'darkSecondaryButton',
      lightKey: 'secondaryButton',
    },
    {
      name: 'Background',
      darkValue: '#0D0E0E',
      lightValue: '#F6F7FF',
      rootName: '--preview-color-background',
      darkKey: 'darkBackground',
      lightKey: 'background',
    },
    {
      name: 'Navigation Bar',
      darkValue: '#151616',
      lightValue: '#FFFFFF',
      rootName: '--preview-color-nav-bar',
      darkKey: 'darkNavigationBar',
      lightKey: 'navigationBar',
    },
  ];

  rootColors = [
    { rootName: '--color-primary', darkKey: 'darkPrimary', lightKey: 'primary' },
    { rootName: '--color-light-dark', darkKey: 'darkSecondary', lightKey: 'secondary' },
    { rootName: '--color-dark', darkKey: 'darkPrimaryFont', lightKey: 'primaryFont' },
    { rootName: '--color-gray', darkKey: 'darkSecondaryFont', lightKey: 'secondaryFont' },
    { rootName: '--color-button-primary', darkKey: 'darkButton', lightKey: 'button' },
    {
      rootName: '--color-button-secondary',
      darkKey: 'darkSecondaryButton',
      lightKey: 'secondaryButton',
    },
    { rootName: '--color-light-white', darkKey: 'darkBackground', lightKey: 'background' },
    { rootName: '--color-white', darkKey: 'darkNavigationBar', lightKey: 'navigationBar' },
  ];

  smallScreenWidth = 1300;
  largeScreenWidth = 1920;
  spanTextWidth!: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateSpanWidth();
  }
  subCustomersLevels: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private dashboardService: DashboardService,
    private sidebarService: SidebarService,
    private usersService: UsersService,
    private alertService: AlertService,
    // private dialogService: DialogService,
    public localStorage: LocalStorageService,
    private configurationService: ConfigurationService,
    private encryptDecryptService: EncryptDescryptService
  ) {
    this.clientConfig = this.localStorage.getCacheConfig();
    this.subCustomersLevels = this.clientConfig?.configuration?.subCustomersLevels;
    this.sidebarService.getAdminSidebarMenu().subscribe((adminSidebarMenu) => {
      this.adminMenuList = adminSidebarMenu;
    });
  }

  ngOnInit(): void {
    this.isCustomerSelected =
      !this.localStorage.getSelectedCustomer() ||
      this.localStorage.getSelectedCustomer() === 'false'
        ? false
        : true;

    this.initializeRouteEvent();
    this.initializeAppTheme();
    this.getCurrentUser();
    this.handleLocalStorage();
    this.getCustomerHierarchy();
    this.fetchSideBarMenuList();

    // Current customer via observable
    this.getCurrentCustomer();

    this.isCustomerSelectedCheck();
    this.updateSpanWidth();
  }

  /**
   * @description Initialize route event
   */
  private initializeRouteEvent(): void {
    this.router.events.subscribe((data: any) => {
      if (data instanceof NavigationEnd) {
        const childRoute = this.activatedRoute.firstChild?.snapshot;
        this.routeConfig = this.activatedRoute.firstChild?.routeConfig?.children;

        if (childRoute && this.routeConfig) {
          const isChildActive = data.urlAfterRedirects.includes(childRoute.url.join('/'));
          this.isParentActive = isChildActive;
        } else {
          this.isParentActive = false;
        }
      }
      this.activeUrl = this.router.url;
    });
  }

  /**
   * @description Initalizes the app theme
   */
  private initializeAppTheme(): void {
    this.dashboardService.getAppTheme().subscribe((data: any) => {
      this.isDarkTheme = data;
      this.checkSubcustomerLevel();
      if (!this.isCustomerSelected) {
        localStorage.removeItem('config');
      }
      this.setColors();
      this.setLogos();
    });
  }

  /**
   * @description Get the current user
   */
  private getCurrentUser(): void {
    this.usersService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
      this.isRole = this.userDetails?.roles;
      if (this.isRole.includes('admin') || this.isRole.includes('support')) {
        if (this.userDetails.customerId != this.clientConfig?.customerId) {
          this.getClientConfiguration();
        }
        this.getAdminCustomerDetails(this.userDetails?.customerId);
      }
    });
  }

  /**
   * @description Handle the local storage
   */
  private handleLocalStorage(): void {
    if (!localStorage.getItem('authToken')) {
      this.router.navigate(['/signin']);
    }
    this.clientConfig = this.localStorage.getCacheConfig();
  }

  /**
   * @description Client Configuration
   *
   * @param customerId
   * @returns
   */
  private getClientConfiguration(customerId?: any) {
    return new Promise((resolve, reject) => {
      this.configurationService
        .getConfigurationSetting(this.clientConfig?.cacheId, customerId)
        .subscribe({
          next: (res: any) => {
            if (res && res.data) {
              this.clientConfig = res.data.configuration;

              const encryptedData = this.encryptDecryptService.encryptData(res.data);
              this.localStorage.setCacheConfig(JSON.stringify(encryptedData));
              this.configurationService.configDetails$.next(res.data);
              this.localStorage.setConfigObservable(this.clientConfig);
              this.localStorage.setSelectedParent(this.localStorage.getCacheConfig()!);

              if (!this.isCustomerSelected) {
                return;
              }

              this.setColors();
              this.setLogos();
              this.checkSubcustomerLevel();
            }
            resolve(true);
          },
          error: (err: any) => {
            this.alertService.error(err.error.message, err.status);
            reject(err);
          },
        });
    });
  }

  checkSubcustomerLevel() {
    const clientConfig = this.localStorage.getCacheConfig()!;
    this.subCustomersLevels = clientConfig?.configuration?.subCustomersLevels;
  }

  setColors() {
    const clientConfig = this.localStorage.getCacheConfig()!;
    if (clientConfig) {
      const colors = clientConfig?.products?.crmConfig?.colors;
      setTimeout(() => {
        if (this.isDarkTheme && document.body.classList.contains('darkMode')) {
          this.rootColors.forEach((color) => {
            document.documentElement.style.setProperty(color.rootName, colors[color.darkKey]);
          });
        } else if (!this.isDarkTheme && !document.body.classList.contains('darkMode')) {
          this.rootColors.forEach((color) => {
            document.documentElement.style.setProperty(color.rootName, colors[color.lightKey]);
          });
        }
      }, 10);
    } else {
      setTimeout(() => {
        if (this.isDarkTheme && document.body.classList.contains('darkMode')) {
          this.rootColors.forEach((color) => {
            document.documentElement.style.setProperty(
              color.rootName,
              this.originalBrandColors[
                this.originalBrandColors.findIndex((ele: any) => ele.darkKey === color.darkKey)
              ].darkValue
            );
          });
        } else if (!this.isDarkTheme && !document.body.classList.contains('darkMode')) {
          this.rootColors.forEach((color) => {
            document.documentElement.style.setProperty(
              color.rootName,
              this.originalBrandColors[
                this.originalBrandColors.findIndex((ele: any) => ele.lightKey === color.lightKey)
              ].lightValue
            );
          });
        }
      }, 10);
    }
  }

  setLogos() {
    const config = this.localStorage.getCacheConfig()?.products;
    const favicon = document.getElementById('favicon-logo')!;

    if (config) {
      this.logoSrc = this.isDarkTheme ? config?.crmConfig?.logo : config?.crmConfig?.logoLight;
      if (config?.crmConfig?.favicon) {
        favicon.setAttribute('href', config?.crmConfig?.favicon);
      } else {
        favicon.setAttribute('href', 'assets/images/auth/favicon.ico');
      }
    } else {
      this.logoSrc = '';
      favicon.setAttribute('href', 'assets/images/auth/favicon.ico');
    }
  }

  /**
   * @description Get Admin Logged in User Details
   *
   * @param customerId
   */
  private getAdminCustomerDetails(customerId: any) {
    this.customerService.getSingleCustomer(customerId).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.adminCustomerName = res[0].name;
      }
    });
  }

  /**
   * @description Fetch sidebar menu list0
   * @param roles
   */
  private async fetchSideBarMenuList(roles?: Array<string>) {
    this.usersService.inProgress.next(true);

    this.superAdminMenuList = this.sidebarService.getSideBarMenus(['superAdmin']);
    this.usersService.inProgress.next(false);
  }

  /**
   * @description Get Customer Hierarchy based on user role (superAdmin, admin)
   */
  private getCustomerHierarchy() {
    this.customerService.getCustomerHierachy().subscribe({
      next: (res: any) => {
        if (this.isRole.includes('superAdmin') && !this.isCustomerSelected) {
          this.customerList = res;
        } else {
          this.customerList = res[0].children;
          this.selectedCustomerList = res[0].children;
          this.getAdminData(res[0]);
        }
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description Show submenu
   *
   * @param itemEl
   * @param title
   */
  showSubmenu(itemEl: HTMLElement, title: any) {
    itemEl.classList.toggle('showMenu');
    const customerId = this.localStorage.getSelectedCustomer();

    if (customerId && customerId != 'false' && itemEl.classList.contains('showMenu')) {
      this.customerService.getCustomerHierachy(customerId).subscribe((res: any) => {
        this.selectedCustomerList = res[0].children;
      });
    }
  }

  /**
   * @description Get admin data for sidebar
   *
   * @param res
   */
  getAdminData(res: any) {
    this.adminMenuList = this.sidebarService.getSideBarMenus([
      this.isRole.includes('support') ? 'support' : 'admin',
    ]);

    this.clientConfig = this.localStorage.getCacheConfig()!;

    if (this.clientConfig && this.clientConfig.configuration) {
      if (!this.clientConfig.configuration.rewardPointsMasterEnabled) {
        this.adminMenuList = this.adminMenuList.filter(
          (menu: any) => menu.title != 'Loyalty Program'
        );
      }

      if (!this.clientConfig.configuration.couponCodesMasterEnabled) {
        this.adminMenuList = this.adminMenuList.filter((menu: any) => menu.title != 'Coupons');
      }

      if (!this.clientConfig.configuration.affiliateFormEnabled) {
        const index = this.adminMenuList.findIndex((ele: any) => ele.title === 'Help Center');
        const affiliateIndex = this.adminMenuList[index].childs.findIndex(
          (ele: any) => ele.title === 'Affiliates'
        );
        this.adminMenuList[index].childs.splice(affiliateIndex, 1);
      }

      if (!this.clientConfig.configuration.customThemeEnabled) {
        this.adminMenuList = this.adminMenuList.filter((menu: any) => menu.title != 'Theme Editor');
      }
    }

    this.isCustomerSelected = true;
    this.selectedCustomerName = res?.name;
    this.selectedCustomerList = res?.children;
  }

  /**
   * @description Get Current customer on Switch to Customer CRM
   */
  private getCurrentCustomer(): void {
    if (this.getCustomerSubscription) {
      this.getCustomerSubscription.unsubscribe();
    }
    this.getCustomerSubscription = this.customerService.getCustomer().subscribe((res: any) => {
      this.selectedCustomerName = '';
      if (res) {
        this.getAdminData(res);
      } else {
        this.isCustomerSelected = false;
        this.selectedCustomerList = [];
      }
    });
  }

  /**
   * @description clear customer selection
   *
   * @param itemEl
   */
  clearCustomerSelection(itemEl: HTMLElement): void {
    this.isCustomerSelected = false;
    this.localStorage.setSelectedCustomer(String(false));
    this.customerService.sendCustomer(false);
    localStorage.removeItem('selectedParent');
    localStorage.removeItem('config');
    const favicon = document.getElementById('favicon-logo')!;
    favicon.setAttribute('href', 'assets/images/auth/favicon.ico');
    this.logoSrc = '';
    this.setColors();
    if (!itemEl.classList.contains('showMenu')) {
      this.getCustomerHierarchy();
    }
  }

  /**
   * @description Check if the customer selected or not. If yes, get the Hierarchy based on the customer Id.
   */
  private isCustomerSelectedCheck(): void {
    this.customerService.getCustomerStatus().subscribe((res: any) => {
      if (res) {
        this.isCustomerSelected =
          !this.localStorage.getSelectedCustomer() ||
          this.localStorage.getSelectedCustomer() === 'false'
            ? false
            : true;

        if (this.isCustomerSelected) {
          this.getChildCustomerHierarchy();
        } else {
          this.getCustomerHierarchy();
        }
      }
    });
  }

  /**
   * @description Get Child Customer Hierarchy
   */
  private getChildCustomerHierarchy(): void {
    const customerId = this.localStorage.getSelectedCustomer();

    this.customerService.getCustomerHierachy(customerId).subscribe((res: any) => {
      this.customerService.sendCustomer(res[0]);
      this.selectedCustomerList = res[0].children;
    });
  }

  /**
   * @description Exist from Admin Login
   */
  async exitSelectedCustomer() {
    this.localStorage.clearSelectedCustomerTree();
    this.localStorage.setSelectedCustomer(this.userDetails.customerId);
    if (this.userDetails.roles.includes('superAdmin')) {
      this.localStorage.removeCacheConfig();
    } else {
      await this.getClientConfiguration(this.userDetails.customerId);
    }
    this.customerService.sendCustomer(this.userDetails.customerId);
  }

  /**
   * @description Check URL for Customer Child
   *
   * @param menu
   * @returns
   */
  findUrl(menu: any) {
    return menu.childs.findIndex((ele: any) => ele.link === window.location.pathname) > -1
      ? true
      : false;
  }

  /**
   * @description Breadcrumbs text width based on the screen resolution
   */
  updateSpanWidth() {
    const screenWidth = window.screen.width;
    if (screenWidth >= this.largeScreenWidth) {
      this.spanTextWidth = 16;
    } else if (screenWidth >= this.smallScreenWidth) {
      this.spanTextWidth = 8;
    }
  }
}
