import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent, ResetPasswordComponent, SigninComponent } from './auth';
import { CoreComponent } from './core/core.component';
import { NotFoundComponent } from './shared/component';
import { AuthGuard, CanMatchRoute } from './shared/service';
import { RoleGuard } from './shared/service/role/role.guard';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customer-management/customer-management.module').then(
            (m) => m.CustomerManagementModule
          ),
        canMatch: [CanMatchRoute],
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
      // { path: 'subscriptions', component: SubscriptionComponent },
      // { path: 'user-management', component: UserComponent },
      // { path: 'user-management/:custId', component: UserComponent },
      // { path: 'plans', component: PlanComponent },
      // {
      //   path: 'reports',
      //   component: ReportsComponent,
      //   children: [
      //     { path: 'revenue', component: RevenueComponent },
      //     { path: 'data-usage', component: DataUsageComponent },
      //     { path: 'subscriber', component: SubscriberComponent },
      //     { path: 'subscription', component: SubscriptionReportComponent },
      //   ],
      // },
      // { path: 'inventory', component: InventoryComponent },
      // { path: 'setting', component: SettingsComponent },
      // {
      //   path: 'help-center',
      //   component: HelpCenterComponent,
      //   children: [
      //     { path: 'contactus', component: ContactusComponent },
      //     { path: 'support', component: SupportComponent },
      //   ],
      // },
      {
        path: 'coupon-management',
        loadChildren: () =>
          import('./modules/coupon-management/coupon-management.module').then(
            (m) => m.CouponManagementModule
          ),
        data: {
          couponCodesMasterEnabled: true,
          expectedRole: ['superAdmin', 'admin'],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'loyalty-point-program',
        loadChildren: () =>
          import('./modules/loyalty-point-program/loyalty-point-program.module').then(
            (m) => m.LoyaltyPointProgramModule
          ),
        data: {
          rewardPointsMasterEnabled: true,
          expectedRole: ['superAdmin', 'admin'],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'subscribers',
        loadChildren: () =>
          import('./modules/subscribe-management/subscribe-management.module').then(
            (m) => m.SubscribeManagementModule
          ),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'user-management',
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
      {
        path: 'plans',
        loadChildren: () => import('./modules/plan/plan.module').then((m) => m.PlanModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
      {
        path: 'help-center',
        loadChildren: () =>
          import('./modules/help-center/help-center.module').then((m) => m.HelpCenterModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin', 'support'] },
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./modules/products/products.module').then((m) => m.ProductsModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
      {
        path: 'theme-editor',
        loadChildren: () =>
          import('./modules/theme-editor/theme-editor.module').then((m) => m.ThemeEditorModule),
        canActivate: [RoleGuard],
        data: { expectedRole: ['superAdmin', 'admin'] },
      },
    ],
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full',
  },

  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
