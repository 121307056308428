<ng-container>
  <div class="super-admin-main">
    <div
      class="super-admin-sidebar"
      [ngClass]="{ 'super-admin-customer-selected': isCustomerSelected }"
      *ngIf="isRole && isRole.includes('superAdmin')"
    >
      <div class="sidebar-brand-parent">
        <a class="sidebar-brand" routerLink="/">
          <img
            class="brand-logo"
            [src]="'/assets/images/auth/logo' + (isDarkTheme ? '-dark.svg' : '-light.svg')"
            alt=""
          />
          <img class="brand-logo-small" src="/assets/images/auth/gb-logo-small.png" alt="" />
        </a>
      </div>

      <ul class="sidebar-menu-list">
        <li *ngFor="let menu of superAdminMenuList" class="flex-column sidebar-main-title" #itemEl>
          <!-- non customer -->
          <div *ngIf="!menu.hasGroup" class="pt-0 pb-0 sidebar-title">
            <a
              [routerLink]="[menu.link]"
              [routerLinkActive]="['activeLink']"
              [routerLinkActiveOptions]="{ exact: true }"
              [tooltip]="{ title: menu.title, isCustomerSelected: isCustomerSelected }"
              placement="right"
              delay="100"
              offset="10"
              (click)="clearCustomerSelection(itemEl)"
            >
              <span class="link-icon sa-link-icon" [innerHTML]="menu.icon"> </span>
              <span class="sidebar-menu-title">
                {{ menu.title }}
              </span>
            </a>
          </div>

          <!-- non customer -->
          <div
            *ngIf="menu.hasGroup && menu.title !== 'Customers'"
            (click)="showSubmenu(itemEl, menu.title)"
            class="pt-0 pb-0 cursor-pointer sidebar-title"
          >
            <a
              [routerLink]="[menu.link + '/revenue']"
              [routerLinkActive]="['activeLink']"
              [routerLinkActiveOptions]="{ exact: true }"
              [ngClass]="{ activeLink: menu.title !== 'Customers' && findUrl(menu) }"
              [tooltip]="{ title: menu.title, isCustomerSelected: isCustomerSelected }"
              placement="right"
              delay="100"
              offset="10"
              (click)="clearCustomerSelection(itemEl)"
            >
              <span class="link-icon sa-link-icon" [innerHTML]="menu.icon"> </span>
              <span class="sidebar-menu-title">
                {{ menu.title }}
              </span>
              <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                <svg
                  class="sidebar-anchor-tab-flex-img arrow"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                    fill="#1C1C1C"
                  />
                </svg>
              </div>
            </a>
          </div>

          <!-- Customer -->
          <div
            *ngIf="menu.hasGroup && menu.title === 'Customers'"
            (click)="showSubmenu(itemEl, menu.title)"
            class="pt-0 pb-0 cursor-pointer sidebar-title"
          >
            <a
              [routerLinkActive]="['activeLink']"
              [routerLink]="['/customers']"
              [routerLinkActiveOptions]="{ exact: false }"
              [tooltip]="{ title: menu.title, isCustomerSelected: isCustomerSelected }"
              placement="right"
              delay="100"
              offset="10"
              (click)="clearCustomerSelection(itemEl)"
            >
              <span class="link-icon sa-link-icon" [innerHTML]="menu.icon"> </span>
              <span class="sidebar-menu-title">
                {{ menu.title }}
              </span>
              <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                <svg
                  class="sidebar-anchor-tab-flex-img arrow"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                    fill="#1C1C1C"
                  />
                </svg>
              </div>
            </a>
          </div>

          <!-- non customer -->
          <div
            class="accordian-base-1 ms-3"
            *ngIf="
              menu.title !== 'Customers' &&
              menu.childs &&
              menu.childs.length > 0 &&
              !isCustomerSelected
            "
          >
            <ul class="sub-menu sub-menu-other">
              <ng-container *ngFor="let child of menu.childs">
                <li
                  *ngIf="child.accessRole.includes('superAdmin')"
                  class="sidebar-sub-title"
                  [routerLinkActive]="['activeLinkChild']"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <a class="nav-link" [routerLink]="[child.link]" *ngIf="!child.external">
                    <span class="">
                      {{ child.title }}
                    </span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>

          <!-- Customer -->
          <div *ngIf="menu.title === 'Customers'" class="accordian-base-1 ms-3">
            <div class="sub-menu">
              <div class="accordion" id="accordionExample">
                <li
                  *ngFor="let child of customerList; index as i"
                  class="sidebar-sub-title"
                  [routerLinkActive]="['activeLinkChild']"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <div class="accordion-item accordion-base-item-1">
                    <h2 class="accordion-header" id="headingOne" [class.disabled]="!child.isActive">
                      <a
                        class="accordion-button collapsed cursor-pointer"
                        [ngClass]="{ 'accordian-after-none': child.children.length === 0 }"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#customerMgmt_' + child._id"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        [routerLink]="['/customers', child?._id]"
                        [appTooltipIfTruncated]="child.name"
                        placement="top"
                        delay="100"
                        offset="10"
                      >
                        <span class="d-inline-block text-truncate" style="max-width: 90%;">{{ child.name }}</span>

                        <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                          <svg
                            class="sidebar-anchor-tab-flex-img arrow"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                              fill="#1C1C1C"
                            />
                          </svg>
                        </div>
                      </a>

                    </h2>
                    <div
                      id="customerMgmt_{{ child._id }}"
                      class="accordion-collapse collapse accordian-base-2"
                      aria-labelledby="'headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div *ngFor="let child2 of child.children; index as j">
                        <div class="">
                          <div class="accordion-item accordion-base-item-2">
                            <h2
                              class="accordion-header"
                              id="headingOne"
                              [class.disabled]="!child2.isActive"
                            >
                              <a
                                class="accordion-button cursor-pointer"
                                [ngClass]="{ 'accordian-after-none': child2.children.length === 0 }"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#customerMgmt_' + child2._id"
                                aria-expanded="false"
                                [routerLink]="['/customers', child?._id, child2?._id]"
                                [tooltip]="child2.name"
                                placement="top"
                                delay="100"
                                offset="10"
                                *ngIf="child2.name.length > 12"
                              >
                                <span>{{
                                  child2.name.length > 12
                                    ? child2.name.slice(0, 12) + '...'
                                    : child2.name
                                }}</span>

                                <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                                  <svg
                                    class="sidebar-anchor-tab-flex-img arrow"
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                      fill="#1C1C1C"
                                    />
                                  </svg>
                                </div>
                              </a>
                              <a
                                class="accordion-button cursor-pointer"
                                [ngClass]="{ 'accordian-after-none': child2.children.length === 0 }"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#customerMgmt_' + child2._id"
                                aria-expanded="false"
                                [routerLink]="['/customers', child?._id, child2?._id]"
                                *ngIf="child2.name.length < 13"
                              >
                                <span>{{
                                  child2.name.length > 12
                                    ? child2.name.slice(0, 12) + '...'
                                    : child2.name
                                }}</span>

                                <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                                  <svg
                                    class="sidebar-anchor-tab-flex-img arrow"
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                      fill="#1C1C1C"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </h2>
                            <div
                              id="customerMgmt_{{ child2._id }}"
                              class="accordion-collapse collapse accordian-base-3"
                              aria-labelledby="'headingOne"
                              [attr.data-bs-parent]="'#customerMgmt_' + child._id"
                            >
                              <div *ngFor="let child3 of child2.children; index as k">
                                <div class="">
                                  <div class="accordion-item accordion-base-item-3">
                                    <h2
                                      class="accordion-header"
                                      id="headingOne"
                                      [class.disabled]="!child3.isActive"
                                    >
                                      <a
                                        class="accordion-button cursor-pointer"
                                        [ngClass]="{
                                          'accordian-after-none': child3.children.length === 0
                                        }"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#customerMgmt_' + child3._id"
                                        aria-expanded="false"
                                        [routerLink]="[
                                          '/customers',
                                          child?._id,
                                          child2?._id,
                                          child3?._id
                                        ]"
                                        [tooltip]="child3.name"
                                        placement="top"
                                        delay="100"
                                        offset="10"
                                        *ngIf="child3.name.length > 10"
                                      >
                                        <span>{{
                                          child3.name.length > 10
                                            ? child3.name.slice(0, 10) + '...'
                                            : child3.name
                                        }}</span>

                                        <div
                                          class="sidebar-anchor-tab-flex-text-container arrow-link-icon"
                                        >
                                          <svg
                                            class="sidebar-anchor-tab-flex-img arrow"
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                              fill="#1C1C1C"
                                            />
                                          </svg>
                                        </div>
                                      </a>
                                      <a
                                        class="accordion-button cursor-pointer"
                                        [ngClass]="{
                                          'accordian-after-none': child3.children.length === 0
                                        }"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#customerMgmt_' + child3._id"
                                        aria-expanded="false"
                                        [routerLink]="[
                                          '/customers',
                                          child?._id,
                                          child2?._id,
                                          child3?._id
                                        ]"
                                        *ngIf="child3.name.length < 11"
                                      >
                                        <span>{{
                                          child3.name.length > 10
                                            ? child3.name.slice(0, 10) + '...'
                                            : child3.name
                                        }}</span>

                                        <div
                                          class="sidebar-anchor-tab-flex-text-container arrow-link-icon"
                                        >
                                          <svg
                                            class="sidebar-anchor-tab-flex-img arrow"
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                              fill="#1C1C1C"
                                            />
                                          </svg>
                                        </div>
                                      </a>
                                    </h2>
                                    <div
                                      id="customerMgmt_{{ child3._id }}"
                                      class="accordion-collapse collapse accordian-base-4"
                                      aria-labelledby="'headingOne"
                                      [attr.data-bs-parent]="'#customerMgmt_' + child2._id"
                                    >
                                      <div *ngFor="let child4 of child3.children; index as l">
                                        <div class="">
                                          <div class="accordion-item accordion-base-item-4">
                                            <h2
                                              class="accordion-header"
                                              id="headingOne"
                                              [class.disabled]="!child4.isActive"
                                            >
                                              <a
                                                class="accordion-button cursor-pointer"
                                                [ngClass]="{
                                                  'accordian-after-none':
                                                    child4.children.length === 0
                                                }"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                [routerLink]="[
                                                  '/customers',
                                                  child?._id,
                                                  child2?._id,
                                                  child3?.id,
                                                  child4?._id
                                                ]"
                                                [tooltip]="child4.name"
                                                placement="top"
                                                delay="100"
                                                offset="10"
                                                *ngIf="child4.name.length > 10"
                                                >{{
                                                  child4.name.length > 10
                                                    ? child4.name.slice(0, 10) + '...'
                                                    : child4.name
                                                }}</a
                                              >
                                              <a
                                                class="accordion-button cursor-pointer"
                                                [ngClass]="{
                                                  'accordian-after-none':
                                                    child4.children.length === 0
                                                }"
                                                data-bs-toggle="collapse"
                                                aria-expanded="false"
                                                [routerLink]="[
                                                  '/customers',
                                                  child?._id,
                                                  child2?._id,
                                                  child3?.id,
                                                  child4?._id
                                                ]"
                                                *ngIf="child4.name.length < 11"
                                                >{{
                                                  child4.name.length > 10
                                                    ? child4.name.slice(0, 10) + '...'
                                                    : child4.name
                                                }}</a
                                              >
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="d-flex align-items-center meta-footer">
        <span
          >Powered By &nbsp;<img src="assets/images/navbar/gb-small.png" alt="gb-small" />
        </span>
      </div>
    </div>

    <!-- admin sidebar -->
    <ng-container *ngIf="isCustomerSelected">
      <div
        class="sidebar-inner"
        [ngClass]="{
          'd-none': show,
          'admin-sidebar-inner': isRole && (isRole.includes('admin') || isRole.includes('support')),
          'super-admin-sidebar-inner': isRole && isRole.includes('superAdmin'),
          'admin-navbar': isRole && (isRole.includes('admin') || isRole.includes('support'))
        }"
        id="sidebar-1"
      >
        <div class="sidebar-brand-parent">
          <a *ngIf="logoSrc" class="sidebar-brand" routerLink="/">
            <img #brandLogo class="brand-logo" [src]="logoSrc" alt="brand-logo" />
          </a>
          <a class="customer-name" *ngIf="!logoSrc && isRole && isRole.includes('superAdmin')">
            <span
              class="w-80 text-overflow-ellipsis"
              *ngIf="selectedCustomerName?.length > spanTextWidth; else noTooltipData"
              [tooltip]="selectedCustomerName"
              placement="right"
              delay="100"
              offset="5"
            >
              {{ selectedCustomerName }}</span
            >
            <ng-template #noTooltipData>
              {{ selectedCustomerName }}
            </ng-template>
          </a>
          <a class="customer-name" *ngIf="!logoSrc && isRole && (isRole.includes('admin') || isRole.includes('support'))">
            <span
              class="w-80 text-overflow-ellipsis"
              *ngIf="adminCustomerName?.length > spanTextWidth; else noTooltipDataAdmin"
              [tooltip]="adminCustomerName"
              placement="right"
              delay="100"
              offset="12"
            >
              {{ adminCustomerName }}
            </span>
            <ng-template #noTooltipDataAdmin>
              {{ selectedCustomerName }}
            </ng-template>
          </a>
        </div>

        <button
          class="btn btn-exit-admin shadow"
          *ngIf="
            isRole &&
            (isRole.includes('admin') || isRole.includes('support')) &&
            userDetails.customerId !== localStorage.getSelectedCustomer()
          "
          (click)="exitSelectedCustomer()"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="exit-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_4183_28732"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4183_28732)">
                <path
                  d="M19 23L17.6 21.6L19.175 20H15V18H19.175L17.6 16.4L19 15L23 19L19 23ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V13.35C20.6833 13.2333 20.3542 13.1458 20.0125 13.0875C19.6708 13.0292 19.325 13 18.975 13C18.325 13 17.7083 13.1 17.125 13.3C16.5417 13.5 16.0083 13.775 15.525 14.125L13.4 12L16.25 9.15L14.85 7.75L12 10.6L9.15 7.75L7.75 9.15L10.6 12L7.75 14.85L9.15 16.25L12 13.4L14.125 15.525C13.775 16.0083 13.5 16.5458 13.3 17.1375C13.1 17.7292 13 18.35 13 19C13 19.35 13.0292 19.6917 13.0875 20.025C13.1458 20.3583 13.2333 20.6833 13.35 21H5Z"
                  fill="white"
                />
              </g>
            </svg>
          </span>
          <span class="white-s-no-wrap"> Exit Customer CRM </span>
        </button>

        <ul class="sidebar-menu-list">
          <li *ngFor="let menu of adminMenuList" class="flex-column sidebar-main-title" #itemEl>
            <!-- Admin non customer -->
            <div *ngIf="!menu.hasGroup" class="pt-0 pb-0 sidebar-title">
              <a
                [routerLink]="[menu.link]"
                [routerLinkActive]="['activeLink']"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="d-flex align-items-center">
                  <span class="link-icon" [innerHTML]="menu.icon"> </span>
                  <span class="">
                    {{ menu.title }}
                  </span>
                </span>
                <div
                  class="new-tag-div"
                  *ngIf="
                    menu?.title === 'Theme Editor' ||
                    menu?.title === 'Products' ||
                    menu?.title === 'Customers'
                  "
                >
                  <span class="mb-0">NEW</span>
                </div>
              </a>
            </div>

            <!-- Admin non customer -->
            <div
              *ngIf="menu.hasGroup && menu.title !== 'Customers'"
              class="pt-0 pb-0 cursor-pointer sidebar-title"
              (click)="showSubmenu(itemEl, menu.title)"
            >
              <a
                [routerLinkActive]="['activeLink']"
                [routerLinkActiveOptions]="{ exact: true }"
                [ngClass]="{ activeLink: findUrl(menu) }"
              >
                <span class="link-icon" [innerHTML]="menu.icon"> </span>
                <span class="">
                  {{ menu.title }}
                </span>
                <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                  <svg
                    class="sidebar-anchor-tab-flex-img arrow"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                      fill="#1C1C1C"
                    />
                  </svg>
                </div>
              </a>
            </div>

            <!-- Admin non customer -->
            <div
              *ngIf="menu.title !== 'Customers' && menu.childs && menu.childs.length > 0"
              class="accordian-base-1"
            >
              <ul class="sub-menu sub-menu-other">
                <li
                  *ngFor="let child of menu.childs"
                  class="sidebar-sub-title"
                  [routerLinkActive]="['activeLinkChild']"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <a class="nav-link" [routerLink]="[child.link]" *ngIf="!child.external">
                    <span class="">
                      {{ child.title }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>

            <!-- Admin customer -->
            <div
              *ngIf="menu.hasGroup && menu.title === 'Customers' && subCustomersLevels > 0"
              class="pt-0 pb-0 cursor-pointer sidebar-title"
              (click)="showSubmenu(itemEl, menu.title)"
            >
              <a
                [routerLinkActive]="['activeLink']"
                [routerLink]="['/customers']"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="link-icon" [innerHTML]="menu.icon"> </span>
                <span class="sidebar-menu-title">
                  {{ menu.title }}
                </span>
                <div class="new-tag-div" *ngIf="menu?.title === 'Customers'">
                  <span class="mb-0">NEW</span>
                </div>
                <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon">
                  <svg
                    class="sidebar-anchor-tab-flex-img arrow"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                      fill="#1C1C1C"
                    />
                  </svg>
                </div>
              </a>
            </div>

            <!-- Admin customer hirarchy -->
            <div *ngIf="menu.title === 'Customers'" class="customer-accordian-container">
              <div class="sub-menu">
                <div
                  class="accordion accordian-base-2"
                  id="accordionExample"
                  *ngIf="selectedCustomerList?.length > 0"
                >
                  <li
                    *ngFor="let child of selectedCustomerList; index as i"
                    class="sidebar-sub-title"
                    [routerLinkActive]="['activeLinkChild']"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <div class="accordion-item accordian-base-item-2">
                      <h2
                        class="accordion-header"
                        id="headingOne"
                        [class.disabled]="!child.isActive"
                      >
                      <!-- [queryParams]="{ pid: child?._id }" -->
                        <a
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#customerMgmt_' + child._id"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          [routerLink]="['/customers', child?._id]"

                          [ngClass]="{ 'accordian-after-none': child.children.length === 0 }"
                          [tooltip]="child.name"
                          placement="top"
                          delay="100"
                          offset="10"
                          *ngIf="child.name.length > 10"
                        >
                          <span>{{
                            child.name.length > 10 ? child.name.slice(0, 10) + '...' : child.name
                          }}</span>

                          <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0">
                            <svg
                              class="sidebar-anchor-tab-flex-img arrow"
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                fill="#1C1C1C"
                              />
                            </svg>
                          </div>
                        </a>
                        <!-- [queryParams]="{ pid: child?._id }" -->
                        <a
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#customerMgmt_' + child._id"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          [routerLink]="['/customers', child?._id]"
                          [ngClass]="{ 'accordian-after-none': child.children.length === 0 }"
                          *ngIf="child.name.length < 11"
                        >
                          <span>{{
                            child.name.length > 10 ? child.name.slice(0, 10) + '...' : child.name
                          }}</span>

                          <div class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0">
                            <svg
                              class="sidebar-anchor-tab-flex-img arrow"
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                fill="#1C1C1C"
                              />
                            </svg>
                          </div>
                        </a>
                      </h2>
                      <div
                        id="customerMgmt_{{ child._id }}"
                        *ngIf="child.children?.length > 0"
                        class="accordion-collapse collapse accordian-base-3"
                        aria-labelledby="'headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div *ngFor="let child2 of child.children; index as j">
                          <div class="">
                            <div class="accordion-item accordian-base-item-3">
                              <h2
                                class="accordion-header"
                                id="headingOne"
                                [class.disabled]="!child2.isActive"
                              >
                              <!-- [queryParams]="{ pid: child2?._id }" -->
                                <a
                                  class="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#customerMgmt_' + child2._id"
                                  aria-expanded="false"
                                  [routerLink]="['/customers', child2?._id]"
                                  [ngClass]="{
                                    'accordian-after-none': child2.children.length === 0
                                  }"
                                  [tooltip]="child2.name"
                                  placement="top"
                                  delay="100"
                                  offset="10"
                                  *ngIf="child2.name.length > 10"
                                >
                                  <span>{{
                                    child2.name.length > 10
                                      ? child2.name.slice(0, 10) + '...'
                                      : child2.name
                                  }}</span>

                                  <div
                                    class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0"
                                  >
                                    <svg
                                      class="sidebar-anchor-tab-flex-img arrow"
                                      width="6"
                                      height="10"
                                      viewBox="0 0 6 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                        fill="#1C1C1C"
                                      />
                                    </svg>
                                  </div>
                                </a>
                                <!-- [queryParams]="{ pid: child2?._id }" -->
                                <a
                                  class="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#customerMgmt_' + child2._id"
                                  aria-expanded="false"
                                  [routerLink]="['/customers', child2?._id]"
                                  [ngClass]="{
                                    'accordian-after-none': child2.children.length === 0
                                  }"
                                  *ngIf="child2.name.length < 11"
                                >
                                  <span>{{
                                    child2.name.length > 10
                                      ? child2.name.slice(0, 10) + '...'
                                      : child2.name
                                  }}</span>

                                  <div
                                    class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0"
                                  >
                                    <svg
                                      class="sidebar-anchor-tab-flex-img arrow"
                                      width="6"
                                      height="10"
                                      viewBox="0 0 6 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                        fill="#1C1C1C"
                                      />
                                    </svg>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="customerMgmt_{{ child2._id }}"
                                class="accordion-collapse collapse accordian-base-4"
                                aria-labelledby="'headingOne"
                                [attr.data-bs-parent]="'#customerMgmt_' + child._id"
                              >
                                <div *ngFor="let child3 of child2.children; index as k">
                                  <div class="">
                                    <div class="accordion-item accordian-base-item-4">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                        [class.disabled]="!child3.isActive"
                                      >
                                      <!-- [queryParams]="{ pid: child3?._id }" -->
                                        <a
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          [routerLink]="['/customers', child3?._id]"
                                          [attr.data-bs-target]="'#customerMgmt_' + child3._id"
                                          aria-expanded="false"
                                          [ngClass]="{
                                            'accordian-after-none': child3.children.length === 0
                                          }"
                                          [tooltip]="child3.name"
                                          placement="top"
                                          delay="100"
                                          offset="10"
                                          *ngIf="child3.name.length > 10"
                                        >
                                          <span>{{
                                            child3.name.length > 10
                                              ? child3.name.slice(0, 10) + '...'
                                              : child3.name
                                          }}</span>

                                          <div
                                            class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0"
                                          >
                                            <svg
                                              class="sidebar-anchor-tab-flex-img arrow"
                                              width="6"
                                              height="10"
                                              viewBox="0 0 6 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                                fill="#1C1C1C"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                        <!-- [queryParams]="{ pid: child3?._id }" -->
                                        <a
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          [routerLink]="['/customers', child3?._id]"
                                          [attr.data-bs-target]="'#customerMgmt_' + child3._id"
                                          aria-expanded="false"
                                          [ngClass]="{
                                            'accordian-after-none': child3.children.length === 0
                                          }"
                                          *ngIf="child3.name.length < 11"
                                        >
                                          <span>{{
                                            child3.name.length > 10
                                              ? child3.name.slice(0, 10) + '...'
                                              : child3.name
                                          }}</span>

                                          <div
                                            class="sidebar-anchor-tab-flex-text-container arrow-link-icon me-0"
                                          >
                                            <svg
                                              class="sidebar-anchor-tab-flex-img arrow"
                                              width="6"
                                              height="10"
                                              viewBox="0 0 6 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0.659675 9.35355C0.446775 9.15829 0.446775 8.84171 0.659675 8.64645L4.25 5.35355C4.4629 5.15829 4.4629 4.84171 4.25 4.64645L0.659675 1.35355C0.446776 1.15829 0.446776 0.841709 0.659675 0.646446C0.872575 0.451184 1.21775 0.451185 1.43065 0.646446L5.02098 3.93934C5.65967 4.52513 5.65968 5.47487 5.02098 6.06066L1.43065 9.35355C1.21775 9.54882 0.872574 9.54882 0.659675 9.35355Z"
                                                fill="#1C1C1C"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </h2>
                                      <div
                                        id="customerMgmt_{{ child3._id }}"
                                        class="accordion-collapse collapse accordian-base-5"
                                        aria-labelledby="'headingOne"
                                        [attr.data-bs-parent]="'#customerMgmt_' + child2._id"
                                      >
                                        <div *ngFor="let child4 of child3.children; index as l">
                                          <div class="">
                                            <div class="accordion-item mb-0 accordian-base-item-5">
                                              <h2
                                                class="accordion-header"
                                                id="headingOne"
                                                [class.disabled]="!child4.isActive"
                                              >
                                              <!-- [queryParams]="{ pid: child4?._id }" -->
                                                <a
                                                  class="accordion-button"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  aria-expanded="false"
                                                  [routerLink]="['/customers', child4?._id]"
                                                  [ngClass]="{
                                                    'accordian-after-none':
                                                      child4.children.length === 0
                                                  }"
                                                  [tooltip]="child4.name"
                                                  placement="top"
                                                  delay="100"
                                                  offset="10"
                                                  *ngIf="child4.name.length > 10"
                                                  >{{
                                                    child4.name.length > 10
                                                      ? child4.name.slice(0, 10) + '...'
                                                      : child4.name
                                                  }}</a
                                                >
                                                <!-- [queryParams]="{ pid: child4?._id }" -->
                                                <a
                                                  class="accordion-button"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  aria-expanded="false"
                                                  [routerLink]="['/customers', child4?._id]"
                                                  [ngClass]="{
                                                    'accordian-after-none':
                                                      child4.children.length === 0
                                                  }"
                                                  *ngIf="child4.name.length < 11"
                                                  >{{
                                                    child4.name.length > 10
                                                      ? child4.name.slice(0, 10) + '...'
                                                      : child4.name
                                                  }}</a
                                                >
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="d-flex align-items-center meta-footer">
          <span>
            Powered By &nbsp;<img src="assets/images/navbar/gb-small.png" alt="gb-small" />
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
