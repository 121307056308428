interface DashboardPermissions {
  dashboard: boolean;
  dashboardRevenueTrend: boolean;
  dashboardRevenueTotal: boolean;
  dashboardTotalSubscribers: boolean;
  dashboardTotalSubscriptions: boolean;
  dashboardActivePlans: boolean;
}

interface CustomerManagementPermissions {
  customers: boolean;
  customersCreate: boolean;
  customersUpdate: boolean;
  customersEnableDisable: boolean;
  customersCustomerDetails: boolean;
  customersSettings: boolean;
  productTRSAgentInvite: boolean;
  customersProductEnableDisable: boolean;
  customersSwitch: boolean;
  customersFeaturesEnableDisable: boolean;
}

interface UserManagementPermissions {
  userManagement: boolean;
  userInvite: boolean;
  userUpdate: boolean;
  userDelete: boolean;
}

interface RoleManagementPermissions {
  roleManagement: boolean;
  roleCreate: boolean;
  roleUpdate: boolean;
  roleEnableDisable: boolean;
  roleDelete: boolean;
}

interface ProductPermissions {
  products: boolean;
  productEnableDisable: boolean;
  productTRSAgentInvite: boolean;
  productTRSInfo: boolean;
  productTRSAgentEnableDisable: boolean;
}

interface PlanPermissions {
  plans: boolean;
  planCreate: boolean;
  planUpdate: boolean;
  planDelete: boolean;
}

interface SubscriberPermissions {
  subscribers: boolean;
  subscriberInfo: boolean;
  subscriberDelete: boolean;
}

interface SubscriptionPermissions {
  subscriptions: boolean;
  subscriptionCreate: boolean;
  subscriptionInfo: boolean;
  subscriptionRefund: boolean;
  subscriptionDelete: boolean;
}

interface InventoryPermissions {
  inventory: boolean;
  inventoryUpload: boolean;
  inventoryInfo: boolean;
}

interface ReportPermissions {
  reports: boolean;
  reportRevenue: boolean;
  reportRevenueEmail: boolean;
  reportSubscriber: boolean;
  reportSubscriberEmail: boolean;
  reportSubscription: boolean;
  reportSubscriptionEmail: boolean;
}

interface SettingsPermissions {
  settings: boolean;
  settingsInventoryUsageReminder: boolean;
  settingsEmailForwarding: boolean;
  settingsAppCurrency: boolean;
  settingsSMTP: boolean;
  settingsPlanReminder: boolean;
}

interface CRMThemePermissions {
  crmThemeEditor: boolean;
}

interface HelpPermissions {
  help: boolean;
  helpContactUs: boolean;
  helpContactUsStatusUpdate: boolean;
  helpContactUsUpdate: boolean;
  helpSupport: boolean;
  helpSupportStatusUpdate: boolean;
  helpSupportUpdate: boolean;
}

export type Role = 'admin' | 'superAdmin' | 'support';

export interface RoleConfig {
  superAdmin: {
    dashboard: DashboardPermissions;
    customerManagement: CustomerManagementPermissions;
    userManagement: UserManagementPermissions;
    roleManagement: RoleManagementPermissions;
    products: ProductPermissions;
    plans: PlanPermissions;
    subscribers: SubscriberPermissions;
    subscriptions: SubscriptionPermissions;
    inventory: InventoryPermissions;
    reports: ReportPermissions;
    settings: SettingsPermissions;
    crmTheme: CRMThemePermissions;
    help: HelpPermissions;
  };
  admin: {
    dashboard: DashboardPermissions;
    customerManagement: CustomerManagementPermissions;
    userManagement: UserManagementPermissions;
    roleManagement: RoleManagementPermissions;
    products: ProductPermissions;
    plans: PlanPermissions;
    subscribers: SubscriberPermissions;
    subscriptions: SubscriptionPermissions;
    inventory: InventoryPermissions;
    reports: ReportPermissions;
    settings: SettingsPermissions;
    crmTheme: CRMThemePermissions;
    help: HelpPermissions;
  };
  support: {
    dashboard: DashboardPermissions;
    customerManagement: CustomerManagementPermissions;
    userManagement: UserManagementPermissions;
    roleManagement: RoleManagementPermissions;
    products: ProductPermissions;
    plans: PlanPermissions;
    subscribers: SubscriberPermissions;
    subscriptions: SubscriptionPermissions;
    inventory: InventoryPermissions;
    reports: ReportPermissions;
    settings: SettingsPermissions;
    crmTheme: CRMThemePermissions;
    help: HelpPermissions;
  };
}

export const RoleConfig: RoleConfig = {
  superAdmin: {
    dashboard: {
      dashboard: true,
      dashboardRevenueTrend: true,
      dashboardRevenueTotal: true,
      dashboardTotalSubscribers: true,
      dashboardTotalSubscriptions: true,
      dashboardActivePlans: true,
    },
    customerManagement: {
      customers: true,
      customersCreate: true,
      customersUpdate: true,
      customersEnableDisable: true,
      customersCustomerDetails: true,
      customersSettings: true,
      productTRSAgentInvite: true,
      customersProductEnableDisable: true,
      customersSwitch: true,
      customersFeaturesEnableDisable: true,
    },
    userManagement: {
      userManagement: true,
      userInvite: true,
      userUpdate: true,
      userDelete: true,
    },
    roleManagement: {
      roleManagement: true,
      roleCreate: true,
      roleUpdate: true,
      roleEnableDisable: true,
      roleDelete: true,
    },
    products: {
      products: true,
      productEnableDisable: true,
      productTRSAgentInvite: true,
      productTRSInfo: true,
      productTRSAgentEnableDisable: true,
    },
    plans: {
      plans: true,
      planCreate: true,
      planUpdate: true,
      planDelete: true,
    },
    subscribers: {
      subscribers: true,
      subscriberInfo: true,
      subscriberDelete: true,
    },
    subscriptions: {
      subscriptions: true,
      subscriptionCreate: true,
      subscriptionInfo: true,
      subscriptionRefund: true,
      subscriptionDelete: true,
    },
    inventory: {
      inventory: true,
      inventoryUpload: true,
      inventoryInfo: true,
    },
    reports: {
      reports: true,
      reportRevenue: true,
      reportRevenueEmail: true,
      reportSubscriber: true,
      reportSubscriberEmail: true,
      reportSubscription: true,
      reportSubscriptionEmail: true,
    },
    settings: {
      settings: true,
      settingsInventoryUsageReminder: true,
      settingsEmailForwarding: true,
      settingsAppCurrency: true,
      settingsSMTP: true,
      settingsPlanReminder: true,
    },
    crmTheme: {
      crmThemeEditor: true,
    },
    help: {
      help: true,
      helpContactUs: true,
      helpContactUsStatusUpdate: true,
      helpContactUsUpdate: true,
      helpSupport: true,
      helpSupportStatusUpdate: true,
      helpSupportUpdate: true,
    },
  },

  admin: {
    dashboard: {
      dashboard: true,
      dashboardRevenueTrend: true,
      dashboardRevenueTotal: true,
      dashboardTotalSubscribers: true,
      dashboardTotalSubscriptions: true,
      dashboardActivePlans: true,
    },
    customerManagement: {
      customers: true,
      customersCreate: true,
      customersUpdate: true,
      customersEnableDisable: true,
      customersCustomerDetails: true,
      customersSettings: true,
      productTRSAgentInvite: true,
      customersProductEnableDisable: true,
      customersSwitch: true,
      customersFeaturesEnableDisable: true,
    },
    userManagement: {
      userManagement: true,
      userInvite: true,
      userUpdate: true,
      userDelete: true,
    },
    roleManagement: {
      roleManagement: true,
      roleCreate: true,
      roleUpdate: true,
      roleEnableDisable: true,
      roleDelete: true,
    },
    products: {
      products: true,
      productEnableDisable: true,
      productTRSAgentInvite: true,
      productTRSInfo: true,
      productTRSAgentEnableDisable: true,
    },
    plans: {
      plans: true,
      planCreate: true,
      planUpdate: true,
      planDelete: true,
    },
    subscribers: {
      subscribers: true,
      subscriberInfo: true,
      subscriberDelete: true,
    },
    subscriptions: {
      subscriptions: true,
      subscriptionCreate: true,
      subscriptionInfo: true,
      subscriptionRefund: true,
      subscriptionDelete: true,
    },
    inventory: {
      inventory: true,
      inventoryUpload: true,
      inventoryInfo: true,
    },
    reports: {
      reports: true,
      reportRevenue: true,
      reportRevenueEmail: true,
      reportSubscriber: true,
      reportSubscriberEmail: true,
      reportSubscription: true,
      reportSubscriptionEmail: true,
    },
    settings: {
      settings: true,
      settingsInventoryUsageReminder: true,
      settingsEmailForwarding: true,
      settingsAppCurrency: true,
      settingsSMTP: true,
      settingsPlanReminder: true,
    },
    crmTheme: {
      crmThemeEditor: true,
    },
    help: {
      help: true,
      helpContactUs: true,
      helpContactUsStatusUpdate: true,
      helpContactUsUpdate: true,
      helpSupport: true,
      helpSupportStatusUpdate: true,
      helpSupportUpdate: true,
    },
  },

  support: {
    dashboard: {
      dashboard: true,
      dashboardRevenueTrend: false,
      dashboardRevenueTotal: false,
      dashboardTotalSubscribers: true,
      dashboardTotalSubscriptions: true,
      dashboardActivePlans: true,
    },
    customerManagement: {
      customers: false,
      customersCreate: false,
      customersUpdate: false,
      customersEnableDisable: false,
      customersCustomerDetails: false,
      customersSettings: false,
      productTRSAgentInvite: false,
      customersProductEnableDisable: false,
      customersSwitch: false,
      customersFeaturesEnableDisable: false,
    },
    userManagement: {
      userManagement: false,
      userInvite: false,
      userUpdate: false,
      userDelete: false,
    },
    roleManagement: {
      roleManagement: false,
      roleCreate: false,
      roleUpdate: false,
      roleEnableDisable: false,
      roleDelete: false,
    },
    products: {
      products: false,
      productEnableDisable: false,
      productTRSAgentInvite: false,
      productTRSInfo: false,
      productTRSAgentEnableDisable: false,
    },
    plans: {
      plans: true,
      planCreate: false,
      planUpdate: false,
      planDelete: false,
    },
    subscribers: {
      subscribers: true,
      subscriberInfo: true,
      subscriberDelete: false,
    },
    subscriptions: {
      subscriptions: true,
      subscriptionCreate: false,
      subscriptionInfo: true,
      subscriptionRefund: false,
      subscriptionDelete: false,
    },
    inventory: {
      inventory: true,
      inventoryUpload: false,
      inventoryInfo: true,
    },
    reports: {
      reports: false,
      reportRevenue: false,
      reportRevenueEmail: false,
      reportSubscriber: false,
      reportSubscriberEmail: false,
      reportSubscription: false,
      reportSubscriptionEmail: false,
    },
    settings: {
      settings: false,
      settingsInventoryUsageReminder: false,
      settingsEmailForwarding: false,
      settingsAppCurrency: false,
      settingsSMTP: false,
      settingsPlanReminder: false,
    },
    crmTheme: {
      crmThemeEditor: false,
    },
    help: {
      help: true,
      helpContactUs: true,
      helpContactUsStatusUpdate: true,
      helpContactUsUpdate: true,
      helpSupport: true,
      helpSupportStatusUpdate: true,
      helpSupportUpdate: true,
    },
  },
};
