import { Component, OnInit } from '@angular/core';
import { CustomerService, LocalStorageService, UsersService } from '../shared/service';
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  showView = false;

  constructor(
    public userService: UsersService,
    private localStorageService: LocalStorageService,
    private customerService: CustomerService
  ) {
    // get user details after login to load main view
    userService.getUserDetails().subscribe({
      next: (result: any) => {
        // if user role is other than superAdmin set selected customer
        if (
          result.roles.length === 1 &&
          (result.roles[0] === 'admin' || result.roles[0] === 'support')
        ) {
          if (
            result.customerId === localStorageService.getSelectedCustomer() ||
            !localStorageService.getSelectedCustomer() ||
            localStorageService.getSelectedCustomer() === 'false'
          ) {
            localStorageService.setSelectedCustomer(result.customerId);
            this.customerService.sendCustomer(result.customerId);
          } else {
            this.customerService.sendCustomer(localStorageService.getSelectedCustomer());
          }
        }
        userService.setCurrentUser(result);
        this.showView = true;
      },
      error: () => {
        this.showView = true;
      },
    });
  }

  ngOnInit(): void {}
}
