import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private _localStorage: LocalStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data['expectedRole']; // Extract the expected role from route data
    const userRole = this._localStorage.getRole(); // Get the user's role

    if (userRole && expectedRole.includes(userRole)) {
      return true; // Role is authorized
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
